import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    Container, TextField, Button, Typography, Snackbar,
    Select, MenuItem, FormControl, InputLabel, Alert as MuiAlert,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterIndividual = ({ api }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { login, user_type, user_name } = useContext(AuthContext);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: 'success' });
    const [position, setPosition] = useState('');
    const [customPosition, setCustomPosition] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    const onSubmit = (data) => {
        const { confirmPassword, position, ...formData } = data;
        const finalPosition = position === 'Other' ? customPosition : position;
        const finalUserData = { ...formData, position: finalPosition };

        fetch(`${api}/register/individual`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(finalUserData)
        })
            .then(response => response.ok ? response.json() : response.json().then(err => { throw err }))
            .then(() => {
                setSnackbarState({ open: true, message: 'Individual user registered successfully!', severity: 'success' });
                setUserData(finalUserData);
                setDialogOpen(true);
            })
            .catch(error => {
                setSnackbarState({ open: true, message: error.detail || 'Failed to register individual user.', severity: 'error' });
            });
    };

    // Handle login after closing the dialog
    const handleCloseDialog = () => {
        setDialogOpen(false);
        if (userData) {
            loginUser(userData);
        }
    };

    const loginUser = (userData) => {
        const formData = new URLSearchParams();
        formData.append('username', userData.email);
        formData.append('password', userData.password);
        fetch(`${api}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        })
            .then(response => response.ok ? response.json() : response.json().then(err => { throw err }))
            .then(data => {
                login(data.access_token);
                user_type(data.user_type);
                user_name(data.user_name);
                setSnackbarState({ open: true, message: 'Login succeeded!', severity: 'success' });
                navigate('/');
            })
            .catch(error => {
                setSnackbarState({ open: true, message: 'Registration succeeded, but login failed.', severity: 'error' });
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState(prevState => ({ ...prevState, open: false }));
    };

    const password = watch("password");

    return (
        <Container
            sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
                margin: 'auto',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Register Individual User
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <TextField
                    label="Username"
                    {...register('user_name', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.user_name}
                    helperText={errors.user_name ? 'Username is required' : ''}
                />
                <TextField
                    label="Email"
                    {...register('email', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email ? 'Email is required' : ''}
                />
                <TextField
                    label="Phone"
                    {...register('phone')}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" error={!!errors.position}>
                    <InputLabel>Position</InputLabel>
                    <Select
                        label="Position"
                        value={position}
                        {...register('position', { required: true })}
                        onChange={(e) => setPosition(e.target.value)}
                    >
                        <MenuItem value="Intern/Trainee">Intern/Trainee</MenuItem>
                        <MenuItem value="Entry-Level">Entry-Level</MenuItem>
                        <MenuItem value="Junior">Junior</MenuItem>
                        <MenuItem value="Mid-Level">Mid-Level</MenuItem>
                        <MenuItem value="Senior">Senior</MenuItem>
                        <MenuItem value="Manager">Manager</MenuItem>
                        <MenuItem value="Director">Director</MenuItem>
                        <MenuItem value="Vice President">Vice President</MenuItem>
                        <MenuItem value="C-Level Executive (CEO, CFO, COO, etc.)">C-Level Executive</MenuItem>
                        <MenuItem value="Business Owner/Entrepreneur">Business Owner</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                {position === 'Other' && (
                    <TextField
                        label="Custom Position"
                        value={customPosition}
                        onChange={(e) => setCustomPosition(e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.customPosition}
                        helperText={errors.customPosition ? 'Custom Position is required' : ''}
                    />
                )}
                {/* <TextField
                    label="Employer"
                    {...register('employer')}
                    fullWidth
                    margin="normal"
                /> */}
                <TextField
                    label="Password"
                    type="password"
                    {...register('password', {
                        required: "Password is required",
                        validate: (value) => {
                            const strongPasswordRegex =
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                            return (
                                strongPasswordRegex.test(value) ||
                                "Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character"
                            );
                        },
                    })}
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    {...register('confirmPassword', {
                        required: true,
                        validate: value => value === password || "Passwords do not match"
                    })}
                    fullWidth
                    margin="normal"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                />
                <Button type="submit" variant="contained" color="primary">
                    Register
                </Button>
            </form>

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>30-Day Free Trial</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You've been given a 30-day free trial
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Start Free Trial
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RegisterIndividual;
