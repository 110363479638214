import React, { useEffect, useState } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Snackbar,
    Alert,
    CircularProgress,
    TablePagination
} from '@mui/material';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const UserOrganizationList = ({ api }) => {
    const [users, setUsers] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [view, setView] = useState('users');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('access_token');

                if (!token) {
                    throw new Error('No access token found');
                }

                const [userResponse, organizationResponse] = await Promise.all([
                    fetch(`${api}/list_users`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    fetch(`${api}/list_organizations`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                if (!userResponse.ok || !organizationResponse.ok) {
                    throw new Error('Failed to fetch data');
                }

                const [userData, orgData] = await Promise.all([
                    userResponse.json(),
                    organizationResponse.json()
                ]);

                setUsers(userData);
                setOrganizations(orgData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to load data. Please try again later.',
                    severity: 'error'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [api]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = async (format) => {
        try {
            const data = view === 'users' ? users : organizations;

            if (!data.length) {
                setSnackbar({
                    open: true,
                    message: 'No data available to download',
                    severity: 'warning'
                });
                return;
            }

            const sheetData = data.map(item => {
                if (view === 'users') {
                    return {
                        ID: item.id,
                        'User Name': item.user_name || '',
                        Email: item.email || '',
                        Phone: item.phone || '',
                        Position: item.position || '',
                        Employer: item.employer || '',
                        'Account Active': item.account_state ? 'Active' : 'Inactive',
                    };
                } else {
                    return {
                        ID: item.id,
                        'Organization Name': item.organization || '',
                        Email: item.email || '',
                        Phone: item.phone || '',
                        'Employees Size': item.employees_size || '',
                        'Account Active': item.account_state ? 'Active' : 'Inactive',
                    };
                }
            });

            const ws = utils.json_to_sheet(sheetData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, view === 'users' ? 'Users' : 'Organizations');

            const fileName = `${view}_${new Date().toISOString().split('T')[0]}.${format}`;

            if (format === 'xlsx') {
                const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
                saveAs(new Blob([excelBuffer]), fileName);
            } else if (format === 'csv') {
                const csvData = write(wb, { bookType: 'csv', type: 'string' });
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                saveAs(blob, fileName);
            }

            setSnackbar({
                open: true,
                message: `Successfully downloaded ${fileName}`,
                severity: 'success'
            });
        } catch (error) {
            console.error('Error downloading file:', error);
            setSnackbar({
                open: true,
                message: 'Failed to download file. Please try again.',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const getColumns = () => {
        if (view === 'users') {
            return [
                { id: 'user_name', label: 'User Name' },
                { id: 'email', label: 'Email' },
                { id: 'phone', label: 'Phone' },
                { id: 'position', label: 'Position' },
                { id: 'employer', label: 'Employer' },
                { id: 'account_state', label: 'Account Active' },
            ];
        } else {
            return [
                { id: 'organization', label: 'Organization Name' },
                { id: 'email', label: 'Email' },
                { id: 'phone', label: 'Phone' },
                { id: 'employees_size', label: 'Employees Size' },
                { id: 'account_state', label: 'Account Active' },
            ];
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    const currentData = view === 'users' ? users : organizations;
    const paginatedData = currentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom textAlign="center">
                User and Organization Management
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(e, value) => {
                        if (value) {
                            setView(value);
                            setPage(0);
                        }
                    }}
                    aria-label="view toggle"
                >
                    <ToggleButton value="users" aria-label="users">
                        Users
                    </ToggleButton>
                    <ToggleButton value="organizations" aria-label="organizations">
                        Organizations
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {getColumns().map((column) => (
                                <TableCell key={column.id}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((item) => (
                            <TableRow key={item.id}>
                                {getColumns().map((column) => (
                                    <TableCell key={column.id}>
                                        {column.id === 'account_state'
                                            ? (item[column.id] ? 'Active' : 'Inactive')
                                            : (item[column.id] || '')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={currentData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 30, 50]}
            />

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload('xlsx')}
                    disabled={!currentData.length}
                >
                    Download as XLSX
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDownload('csv')}
                    disabled={!currentData.length}
                >
                    Download as CSV
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UserOrganizationList;